<!-- 退出登录 -->
<template>
  <div>
    <el-button type="primary" @click="onLayout">退出登录</el-button>
  </div>
</template>

<script>
export default {
  methods: {
    onLayout() {
      this.$http.get('/api/logout').then(() => {
        removeToken()
        this.$router.replace({ name: 'Login'})
      }).catch(err => {
        this.$notify(err.msg || '退出失败')
      })
    },
    // removeToken
  },
};
</script>

<style lang='less' scoped>
</style>
